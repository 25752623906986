<div class="container-fluid">
  <!-- <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label for="validationCustom01" class="col-xl-3 col-sm-4 pl-0">Category</label>
            <select class="form-control col-xl-8 col-sm-7" [(ngModel)]="category" [ngModelOptions]="{standalone: true}"
              (ngModelChange)="changeCategory($event)">
              <option value="-1">All</option>
              <option *ngFor="let category of categories" [ngValue]="category.id">
                {{category.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="validationCustom01" class="col-xl-3 col-sm-4 pl-0">Subcategory</label>
            <select class="form-control col-xl-8 col-sm-7" [(ngModel)]="subcategory"
              [ngModelOptions]="{standalone: true}" (ngModelChange)="changeSubcategory($event)">
              <option value="-1">All</option>
              <option *ngFor="let subcategory of subcategories" [ngValue]="subcategory.id">
                {{subcategory.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="row products-admin ratio_asos">
    <div class="col-xl-2 col-sm-6 col-md-3" *ngFor="let product of product_list; let pIndex = index">
      <div class="card">
        <div class="card-body product-box">
          <div class="img-wrapper">
            <div class="lable-block">
              <!-- <span class="lable3" *ngIf="product.new">new</span> -->
              <span class="lable4" style="border: solid 1px white;
              padding: 3px;
              background: white;
              color: black;
              opacity: 0.5;" *ngIf="product.sale">On Sale</span>
            </div>
            <div class="front">
              <a href="javascript:void(0)"><img
                  [src]="[product.images[product.indexImage].src || 'assets/images/product/placeholder.jpg']"
                  class="img-fluid blur-up lazyload bg-img" alt=""></a>
              <div class="product-hover">
                <ul>
                  <li>
                    <button class="btn" type="button" data-original-title="" title=""><i
                        class="icon-pencil-alt"></i></button>
                  </li>
                  <li>
                    <button class="btn" type="button" data-toggle="modal" data-target="#exampleModalCenter"
                      data-original-title="" title=""><i class="icon-trash"></i></button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="product-detail">
            <div class="rating"><i class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i> <i
                class="fa fa-star"></i> <i class="fa fa-star"></i></div>
            <span class="badge badge-primary" style="font-size:12px;margin-bottom: 20px;" *ngIf="product.is_membership && product.is_membership == true
                ">Membership</span><br />
            <a href="javascript:void(0)">
              <h6>{{ product.title | slice:0:maxTextLength }}{{ product.title.length > maxTextLength ? '...' : '' }}
              </h6>
            </a>
            <h4 *ngIf="product.sale"><span style="font-size: 16px;">KSH</span> {{product.discountPrice}} <del><span
                  style="font-size: 16px;">KSH</span> {{product.price}}</del></h4>
            <h4 *ngIf="!product.sale"><span style="font-size: 16px;">KSH</span> {{product.price}}</h4>
            <ul class="color-variant" *ngIf="product.variants[0].color !== 'none'">
              <div class="circular-round-none-new" *ngFor="let variant of product.variants; let i = index"
                [ngClass]="{'circular-round-new': (i == product.indexImage)}">
                <li [class]="variant.color" [ngStyle]="{'background-color': variant.color}"
                  (click)="changeVariant(pIndex, i)">
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col" *ngIf="!this.loading && this.product_list.length == 0">
      <div class="col-sm-12 text-center section-b-space mt-5 no-found">
        <!-- <img src="assets/images/empty-search.jpg" class="img-fluid mb-4"> -->
        <h3>Empty List</h3>
        <!-- <a [routerLink]="['/shop/collection/stocks']" class="btn btn-solid">continue shopping</a> -->
      </div>
    </div>
    <div class="col" *ngIf="this.loading">
      <div class="col-sm-12 text-center section-b-space mt-5 no-found">
        <h3>loading ...</h3>
      </div>
    </div>
  </div>
</div>
