export class CallaBackendConfig {
  public static BASEURL = 'https://service-dev.callastyle.co.ke/api/v1';
  // public static BASEURL = 'http://localhost:8080/api/v1';
  public static BASEURL_FIREBASE =
    'https://fidemecommerce-28d0d-default-rtdb.firebaseio.com';
  // public static BASEURL = 'http://0.0.0.0:8080/api/v1'
  public static REGISTER = CallaBackendConfig.BASEURL + '/sso/register';
  public static ACTIVATE_USER = CallaBackendConfig.BASEURL + '/sso/activate';
  public static LOGIN = CallaBackendConfig.BASEURL + '/sso/login';
  public static UPDATE_USER = CallaBackendConfig.BASEURL + '/sso/update';
  //Category + Subcategory
  public static CATEGORY = CallaBackendConfig.BASEURL + '/category';
  public static CATEGORIES = CallaBackendConfig.BASEURL + '/categories';
  public static SUB_CATEGORY = CallaBackendConfig.BASEURL + '/subcategory';
  public static SUB_CATEGORIES = CallaBackendConfig.BASEURL + '/subcategories';
  //Products
  public static PRODUCT =
    CallaBackendConfig.BASEURL_FIREBASE + '/products.json';
  public static UPRODUCT = CallaBackendConfig.BASEURL + '/uproduct';
  public static UNVERIFIED_PRODUCTS =
    CallaBackendConfig.BASEURL + '/admin/unverified/products';
  public static EXPIRED_PRODUCTS =
    CallaBackendConfig.BASEURL + '/admin/expired/products';
  public static DECLINED_PRODUCTS =
    CallaBackendConfig.BASEURL + '/admin/declined/products';
  public static DELETE_USER_PRODUCT =
    CallaBackendConfig.BASEURL + '/unverified/product';
  public static UPRODUCT_DECLINE =
    CallaBackendConfig.BASEURL + '/uproduct/decline';
  public static PRODUCT_UPDATE =
    CallaBackendConfig.BASEURL_FIREBASE + '/products';
  public static GET_AMDIN_FIREBASE_TOKEN =
    CallaBackendConfig.BASEURL + '/sso/get-admin-upload-token';

  public static SUBSCRIPTION = CallaBackendConfig.BASEURL + '/subscription';
  public static SUBSCRIPTION_ADMIN =
    CallaBackendConfig.BASEURL + '/admin/subscriptions';

  public static COUNTY = CallaBackendConfig.BASEURL + '/county';
  public static COUNTIES = CallaBackendConfig.BASEURL + '/counties';

  public static REGION = CallaBackendConfig.BASEURL + '/region';
  public static REGIONS = CallaBackendConfig.BASEURL + '/regions';

  public static GENERAL_STATISTICS =
    CallaBackendConfig.BASEURL + '/admin/stats';
  public static GET_USER = CallaBackendConfig.BASEURL + '/admin/users';
  public static UPDATE_USER_COMMISSION =
    CallaBackendConfig.BASEURL + '/sso/user/referral/commision';
}
